import React from "react";
import { useNavigate } from "react-router-dom";

const Aibar = () => {
    const navigate = useNavigate();
    // localStorage.clear();
    // localStorage.removeItem("sheetdata");
    let sheetData = JSON.parse(localStorage.getItem('sheetdata'));

    localStorage.removeItem("barcodelink");
    const onstart = () => {
        navigate(`/signup/${sheetData.value}`);
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <div className="form_wrapper position-relative flex-column">
                            <div className="rounded start_page">
                                <div className="row justify-content-center">
                                    <div className="col-xl-6 col-12">
                                        <img src="images/logo.png" className="img-fluid" alt="" />
                                    </div>
                                    <div className="col-xl-10">
                                        <h3 className="mt-md-6 mt-2">
                                            Welcome to the T(AI)STE Bar!
                                        </h3>
                                    </div>
                                    <div className="col-xl-9">
                                        <h3 className="mt-md-6 mt-4">
                                            Please go ahead and sample the tasting paddle from left to right and follow the prompts to complete your personalized beverage analysis.
                                        </h3>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    name="next"
                                    className="next rounded btn btn-lg My-Button-see-live px-4 p-2"
                                    onClick={() => onstart()}
                                >
                                    Start
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Aibar;

import React from 'react'
import { useNavigate } from 'react-router-dom';

const PagenotFound = () => {
    const navigate = useNavigate();
    const onback= () =>{
      console.log("first")
      navigate("/")
    }
  return (
    <>
    <div className="container-fluid-md">
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 col-lg-8">
        <div className="form_wrapper position-relative flex-column">
          <h1 style={{ color: "yellow" }}>Oops ! Page Not Found</h1>
          <button
            className="next rounded btn btn-sm My-Button-see-live px-5 p-2 mt-4"
            onClick={() => onback()}
          >
            Back to Home
          </button>
          </div>
        </div>
      </div>
      </div>
    </>
  )
}

export default PagenotFound
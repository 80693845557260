import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getData } from "../utils/index";
let userData = JSON.parse(localStorage.getItem('userdata'));


const Surveydetail = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const [surveyData, setSurveyData] = useState(null)
    const [question, setQuestion] = useState(null)
    const [answer, setAnswer] = useState(null)

    async function sheet() {
        let rows = await getData(process.env.REACT_APP_GOOGLE_SHEET_ID, 2);
        let surveyfilter = rows.filter(row =>
            row.surveyid === id
        );
        setSurveyData(surveyfilter);

        let sheetQuestion = await getData(surveyfilter[0].sheetid, 0);
        let sheetAnswer = await getData(surveyfilter[0].sheetid, 1);
        let answerfilter = sheetAnswer.filter(row =>
            row.id === id
        );
        setQuestion(sheetQuestion);
        setAnswer(answerfilter);
    }

    useEffect(() => {
        sheet();
    }, []);

    const handleClick = (e) => {
        navigate(`/dashboard`);
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <div className=" position-relative flex-column">
                            <div className="rounded start_page">
                                <div className="row justify-content-center mt-3">
                                    <div className="col-xl-6 col-12">
                                        <img src="/images/formlogo.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                                {!surveyData ? <h3>Loading. . .</h3> :
                                    surveyData != null && surveyData[0].aiflag === 'FALSE' ?

                                        <>
                                            <div className="row justify-content-center">
                                                <div className="col-md-8 col-10 text-start  completed-survey ">
                                                    <h2 className="mb-0"><a onClick={handleClick} ><img src="/images/arrow_1.svg" className="img-fluid me-3" alt="" /></a> Completed Surveys</h2>
                                                    {!question ? <h3>Loading. . .</h3> : question?.map((val, index) => {
                                                        let ans = answer?.map((val) => { return val._rawData[index]; });
                                                        return (<>
                                                            <div className="mt-5">
                                                                <h5><span className="me-3">Q{index + 1}.</span>{val.Question}</h5>
                                                                <p className="mt-4"><span className="me-4">A.</span>{ans ? ans[0] : ""}</p>
                                                            </div>
                                                        </>)
                                                    })}
                                                </div>
                                            </div>
                                        </>

                                        :
                                        <>
                                            <div className="row justify-content-center">
                                                <div className="col-xl-6 col-12">
                                                    <img src={surveyData[0].qrcode} className="img-fluid" alt="" height={150} width={150} />
                                                </div>
                                            </div>
                                            <div className="row justify-content-center mt-3">
                                                <div className="col-md-8 col-10 text-start  completed_qrsurvey">
                                                    <h2 className="mb-4"><a onClick={handleClick}><img src="/images/arrow_1.svg" className="img-fluid me-3" alt="" /></a> Completed Surveys</h2>
                                                    {!question ? <h3>Loading. . .</h3> : question?.map((val, index) => {
                                                        let ans = answer?.map((val) => { return val._rawData[index]; });
                                                        return (
                                                            <>
                                                                <div className="mt-3 d-flex justify-content-between">
                                                                    <h5><span>Base {index + 1}</span> <span className="mx-3">-</span> {val.Question}</h5>
                                                                    <h5><span className="mx-4">:</span>{ans ? ans[0] : ""}</h5>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </>
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Surveydetail;
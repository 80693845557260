import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

var sheetData;
var userData;
var barcodelink;

const ThankYou = () => {
  const navigate = useNavigate();
  
  // useEffect(() => {
    userData = JSON.parse(localStorage.getItem('userdata'));
    sheetData = JSON.parse(localStorage.getItem('sheetdata'));
    barcodelink = localStorage.getItem('barcodelink');
  // }, [])
  
  const onBack = () => {
    localStorage.removeItem("barcodelink");
    if (sheetData.aiflag === "TRUE") {
      if (userData.usertype === "registered") {
        navigate("/dashboard");
      } else {
        navigate("/aibar");
      }
    } else {
      navigate("/");
    }
  };
  
  return (
    <>
      <div className="container-fluid-md">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="form_wrapper position-relative flex-column">
              <div className="rounded start_page">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-md-6 col-10">
                    <img src="images/logo.png" className="img-fluid" alt="" />
                  </div>
                  {sheetData?.aiflag === "TRUE" ?
                    <>
                      <div className="col-12">
                        <img src={barcodelink} className="img-fluid" alt="" />
                      </div>
                      <div className="col-xl-9">
                        <h4 className="mt-md-4 mt-2">
                          Thanks for getting involved and tasting the future with us! Your personalized AI beer is being prepared now and will be brought to you shortly. Please save the above QR code if you would like to recreate your beverage in the future!
                        </h4>
                      </div>
                    </>
                    :
                    <>
                      <div className="col-12">
                        <h3 className="mt-md-4 mt-2">Thanks!</h3>
                      </div>
                      <div className="col-xl-9">
                        <h3 className="mt-md-4 mt-2">
                          The brewers appreciate your collaboration in the pursuit
                          of the best beer possible.
                        </h3>
                      </div>
                      <div className="col-xl-10">
                        <h3 className="mt-md-4 mt-4 mx-auto head">
                          You have also stepped into the future and helped to
                          democratise Ai!
                        </h3>
                      </div>
                      <div className="col-xl-12">
                        <h3 className="my-md-4 my-3">
                          For more info please check out&nbsp;
                          <a href="https://www.deepliquid.ai/" className="text-white">
                            www.deepliquid.ai
                          </a>
                        </h3>
                      </div>
                    </>
                  }
                  {sheetData?.aiflag === "FALSE" ?
                    <div className="col-xl-12">
                      <button
                        className="next rounded btn btn-lg My-Button-see-live px-4 p-2"
                        type="button"
                        onClick={onBack}
                      >
                        Go to Home
                      </button>
                    </div>
                    :
                    <div className="col-xl-12">
                      <button
                        className="next rounded btn btn-lg My-Button-see-live px-4 p-2"
                        type="button"
                        onClick={onBack}
                      >
                        Finish
                      </button>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;

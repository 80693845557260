import React from "react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { toast } from 'material-react-toastify';
import axios from 'axios';

export const addData = async (sheet, sheetid, data) => {
    const doc = new GoogleSpreadsheet(sheet);
    await doc.useServiceAccountAuth({
        client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
            /\\n/gm,
            "\n"
        ),
    });
    await doc.getInfo();
    if (sheetid !== "") {
        sheet = doc.sheetsByIndex[sheetid];
    }
    await sheet.addRow(data);
};

export const getData = async (sheet, sheetid = "") => {
    const doc = new GoogleSpreadsheet(sheet);
    await doc.useServiceAccountAuth({
        client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
            /\\n/gm,
            "\n"
        ),
    });
    await doc.getInfo();
    if (sheetid !== "") {
        sheet = doc.sheetsByIndex[sheetid];
    }
    const rows = await sheet.getRows();
    return rows;
};

export const getHeader = async (sheet, sheetid = "") => {
    const doc = new GoogleSpreadsheet(sheet);
    await doc.useServiceAccountAuth({
        client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
            /\\n/gm,
            "\n"
        ),
    });
    await doc.getInfo();
    if (sheetid !== "") {
        sheet = doc.sheetsByIndex[sheetid];
    }
    const rows = await sheet.getRows();
    return sheet.headerValues;
};

export const getFormData = async (sheet, sheetid = "") => {
    const doc = new GoogleSpreadsheet(sheet);
    await doc.useServiceAccountAuth({
        client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
        private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
            /\\n/gm,
            "\n"
        ),
    });
    await doc.getInfo();
    if (sheetid !== "") {
        sheet = doc.sheetsByIndex[sheetid];
    }
    const rows = await sheet.getRows();
    return rows;
};

export const sendEmail = (payload) => {

    let data = JSON.stringify(payload);
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://us-central1-bvbeer.cloudfunctions.net/function-2',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };

    axios.request(config)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });

};

export const SendNotification = ({ type, message, duration }) => {
    let options = {
        autoClose: 3000 || duration
    }
    if (type === 's') {
        toast.success(message, options)
    } else if (type === 'e') {
        toast.error(message, options)
    } else if (type === 'w') {
        toast.warn(message, options)
    }
}


export const score_to_volume = (s1, s2, s3, s4, s5, s6) => {
    s1 = Number(s1);
    s2 = Number(s2);
    s3 = Number(s3);
    s4 = Number(s4);
    s5 = Number(s5);
    s6 = Number(s6);
    let sum = s1 + s2 + s3 + s4 + s5 + s6;
    if (sum === 0) {
        return s1.toString().padStart(2, "0") + "," + s2.toString().padStart(2, "0") + "," + s3.toString().padStart(2, "0") + "," + s4.toString().padStart(2, "0") + "," + s5.toString().padStart(2, "0") + "," + s6.toString().padStart(2, "0");
    } else {
        let v1 = Number(Math.round(s1 / sum * 100.0));
        let v2 = Number(Math.round(s2 / sum * 100.0));
        let v3 = Number(Math.round(s3 / sum * 100.0));
        let v4 = Number(Math.round(s4 / sum * 100.0));
        let v5 = Number(Math.round(s5 / sum * 100.0));
        let v6 = Number(Math.round(s6 / sum * 100.0));
        // let v1 = Number(s1 / sum * 100.0);
        // let v2 = Number(s2 / sum * 100.0);
        // let v3 = Number(s3 / sum * 100.0);
        // let v4 = Number(s4 / sum * 100.0);

        v1 = Math.min(v1, 99);
        v2 = Math.min(v2, 99);
        v3 = Math.min(v3, 99);
        v4 = Math.min(v4, 99);
        v5 = Math.min(v5, 99);
        v6 = Math.min(v6, 99);
        if ((v1 + v2 + v3 + v4 + v5 + v6) !== 100) {
            if (v6 === 99) {
                // in which case, arbitrarily pick v3
                // v3 = 100 - (v1 + v2 + v4);
                v5 = 100 - (v1 + v2 + v3 + v4 + v6);
            } else {
                // v4 = 100 - (v1 + v2 + v3);
                v6 = 100 - (v1 + v2 + v3 + v4 + v5);
            }
        }
        return v1.toString().padStart(2, "0") + "," + v2.toString().padStart(2, "0") + "," + v3.toString().padStart(2, "0") + "," + v4.toString().padStart(2, "0") + "," + v5.toString().padStart(2, "0") + "," + v6.toString().padStart(2, "0");
    }
    // Values cannot exceed 99
}

export const new_score_to_volume = (s1, s2, s3, s4, s5, s6) => {
    /**
  The rules:
  D1. Scores range from 0 to 10, integer.
  D2. Output percentages must add to 100.
  D3. Each integer output percentage in range 0 to 99.
  D4. The output percentages are proportional to the input scores.
  D5. The first beer is the concentrate, its percentage is reduced to a factor of 0.25.  
     The deficit is spread among the remaining 5 beers.
  D6. The first (concentrate) beer cannot have a percentage in excess of 40.  The deficit is added to the last (base) beer.
  7. The last beer is the base beer, it makes up the deficit when percentages don't add to 100, for example when rounding.
  D8. If the scores are all zero, the output is 16%, 16%, 16%, 16%, 16%, 20%
  9. If we end up with 100% of beer 6, return 0,0,0,0,1,99
   */

    s1 = parseInt(s1);
    s2 = parseInt(s2);
    s3 = parseInt(s3);
    s4 = parseInt(s4);
    s5 = parseInt(s5);
    s6 = parseInt(s6);

    // rule 1

    if (!(0 <= s1 && s1 <= 10)) {
        throw new Error("AssertionError");
    }
    if (!(0 <= s2 && s2 <= 10)) {
        throw new Error("AssertionError");
    }
    if (!(0 <= s3 && s3 <= 10)) {
        throw new Error("AssertionError");
    }
    if (!(0 <= s4 && s4 <= 10)) {
        throw new Error("AssertionError");
    }
    if (!(0 <= s5 && s5 <= 10)) {
        throw new Error("AssertionError");
    }
    if (!(0 <= s6 && s6 <= 10)) {
        throw new Error("AssertionError");
    }

    let sum = s1 + s2 + s3 + s4 + s5 + s6;

    if (sum === 0) {
        // Rule 8
        return [16, 16, 16, 16, 16, 20];
    } else {

        // Rule 4
        var v1 = Math.round(s1 / sum * 100);
        var v2 = Math.round(s2 / sum * 100);
        var v3 = Math.round(s3 / sum * 100);
        var v4 = Math.round(s4 / sum * 100);
        var v5 = Math.round(s5 / sum * 100);
        var v6 = Math.round(s6 / sum * 100);

        // Rule 5
        const v1_orig = v1;
        v1 = Math.round(v1 * 0.25);
        const v1_diff = v1_orig - v1;
        if (v1_diff >= 0) {
            v2 += Math.round(v1_diff / 5);
            v3 += Math.round(v1_diff / 5);
            v4 += Math.round(v1_diff / 5);
            v5 += Math.round(v1_diff / 5);
            v6 += Math.round(v1_diff / 5);
        }

        // Rule 6
        if (v1 > 40) {
            v6 += v1 - 40;
            v1 = 40;
        }

        // Rule 3: Values cannot exceed 99
        v1 = Math.min(v1, 99);
        v2 = Math.min(v2, 99);
        v3 = Math.min(v3, 99);
        v4 = Math.min(v4, 99);
        v5 = Math.min(v5, 99);
        v6 = Math.min(v6, 99);

        if ((v1 + v2 + v3 + v4 + v5 + v6) !== 100) {
            // Special case when, due to rounding, the numbers don't exactly add to 100.
            // Arbitrarily modify the last number so they add to 100, _unless_ the last number is 99!
            v6 = 100 - (v1 + v2 + v3 + v4 + v5);
            // v6 can now be negative, if the scores sum to > 100.  Put this amount in the higest other score.
            if (v6 < 0) {
                var scores_vec = [v1, v2, v3, v4, v5];
                var max_idx = scores_vec.indexOf(Math.max(...scores_vec));
                scores_vec[max_idx] += v6;
                v1 = scores_vec[0];
                v2 = scores_vec[1];
                v3 = scores_vec[2];
                v4 = scores_vec[3];
                v5 = scores_vec[4];
                v6 = 0;
            } else if (v6 === 100) {
                // in which case, arbitrarily pick v5
                return [0, 0, 0, 0, 1, 99];
            }
        }

        // Rule 3
        if (!(0 <= v1 && v1 <= 99)) {
            throw new Error(`v1 = ${v1} for scores ${s1}, ${s2}, ${s3}, ${s4}, ${s5}, ${s6}`);
        }
        if (!(0 <= v2 && v2 <= 99)) {
            throw new Error(`v2 = ${v2} for scores ${s1}, ${s2}, ${s3}, ${s4}, ${s5}, ${s6}`);
        }
        if (!(0 <= v3 && v3 <= 99)) {
            throw new Error(`v3 = ${v3} for scores ${s1}, ${s2}, ${s3}, ${s4}, ${s5}, ${s6}`);
        }
        if (!(0 <= v4 && v4 <= 99)) {
            throw new Error(`v4 = ${v4} for scores ${s1}, ${s2}, ${s3}, ${s4}, ${s5}, ${s6}`);
        }
        if (!(0 <= v5 && v5 <= 99)) {
            throw new Error(`v5 = ${v5} for scores ${s1}, ${s2}, ${s3}, ${s4}, ${s5}, ${s6}`);
        }
        if (!(0 <= v6 && v6 <= 99)) {
            throw new Error(`v6 = ${v6} for scores ${s1}, ${s2}, ${s3}, ${s4}, ${s5}, ${s6}`);
        }

        // Rule 2
        let sum_new = v1 + v2 + v3 + v4 + v5 + v6;
        if (sum_new !== 100) {
            throw new Error(`Sum = ${sum_new} for scores ${s1}, ${s2}, ${s3}, ${s4}, ${s5}, ${s6}`);
        }

        return [v1, v2, v3, v4, v5, v6];
    }
}
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from "react-bootstrap";
function Mymodalpopup(props) {
    return (
        <>
            <Modal show={props.show} onHide={props.onClose} className='info-modal' aria-labelledby="contained-modal-title-vcenter"
                centered>
                {/* <Modal.Header closeButton>
                    <Modal.Title>Modal heading</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>You have pending surveys in your account. Would you like to complete them before proceeding?</Modal.Body>
                <Modal.Footer className='justify-content-start'>
                    <Button className="btn My-Button-see-live px-3 p-1" onClick={props.onSave} >
                        Continue
                    </Button>
                    <Button className='btn signup-skip px-4 p-1' onClick={props.onClose} >
                        Skip
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default Mymodalpopup;
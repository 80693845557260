import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { useNavigate } from "react-router-dom";

export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };
  static Page = ({ children }) => children;

  constructor(props) {
    super(props);
    this.state = {
      page: this.props.page,
      values: props.initialValues || {},
    };
  }

  next = (values) => {
    this.setState((state) => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values,
    }));
  }

  previous = () =>
    this.setState((state) => ({
      page: Math.max(state.page - 1, 0),
    }));

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = (values) => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };




  handleSubmit = (values) => {
    const { children, onSubmit, onClick } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values);
    } else {
      this.next(values);
      onClick(values);
      // titleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  render() {
    const { children } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            {children && (
              <Form
                validate={this.validate}
                onSubmit={this.handleSubmit}
              >
                {({ handleSubmit, submitting, values }) => (
                  <form
                    onSubmit={handleSubmit}
                    id="msform"
                    className="form_wrapper"
                  >
                    <fieldset className="rounded">
                      <div className="mb-4 text-center">
                        <img src="/images/formlogo.png" className="img-fluid text-center form-logo" alt="" />
                      </div>
                      {activePage}
                      {/* {page > 0 && (
                <button type="button" onClick={this.previous}>
                  « Previous
                </button>
              )} */}
                      {!isLastPage && (
                        <button
                          className="next rounded btn btn-lg My-Button-see-live px-4 p-2"
                          type="submit"
                        >
                          Next
                        </button>
                      )}
                      {/* <img src={check}/> */}
                      {isLastPage && (
                        <button
                          type="submit"
                          className="next rounded btn btn-lg My-Button-see-live px-4 p-2"
                          disabled={submitting}
                        >
                          {this.props.loader ? "Loading..." : "Submit"}
                        </button>
                      )}
                    </fieldset>
                    {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  </form>
                )}
              </Form>
            )}
          </div>
        </div>
      </>
    );
  }
}

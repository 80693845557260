import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate, useParams } from 'react-router-dom';

function Header(props) {
    const navigate = useNavigate();
    let { id } = useParams();
    let userData = JSON.parse(localStorage.getItem('userdata'));
    let sheetData = JSON.parse(localStorage.getItem('sheetdata'));
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <button
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </button>
    ));
    const logout = (e) => {

        if (sheetData.aiflag === "TRUE") {
            localStorage.removeItem('userdata');
            navigate("/aibar");
        } else {
            localStorage.clear();
            navigate("/");
        }
    }

    const dashboard = (e) => {
        navigate("/dashboard");
    }
    const changepassword = (e) => {
        navigate(`/changepassword`);
    }
    const home = (e) => {
        if (sheetData.aiflag === "TRUE") {
            navigate("/aibar");
        } else {
            navigate("/");
        }
    }
    return (
        <>
            <div className='top_toggle'>
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        <i className="fa fa-bars fa-2x" aria-hidden="true"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="home" onClick={(e) => {
                            home(e);
                        }} >Home</Dropdown.Item>
                        {userData.usertype === "registered" ?
                            <>
                                <Dropdown.Item eventKey="dashboard" onClick={(e) => {
                                    dashboard(e);
                                }} >Dashboard</Dropdown.Item>
                                <Dropdown.Item eventKey="changepassword" onClick={(e) => {
                                    changepassword(e);
                                }} >Change Password</Dropdown.Item>
                                <Dropdown.Item eventKey="logout" onClick={(e) => {
                                    logout(e);
                                }} >Logout</Dropdown.Item>
                            </> : <></>
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </>
    );
}
export default Header;
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PagenotFound from './PagenotFound'
import ThankYou from './components/ThankYou'
import Typeform from './components/Typeform'
import Resumeform from './components/Resumeform'
import Start from './components/Start'
import Aibar from './components/Aibar'
import Landing from './components/Landing'
import Info from './components/Info'
import Login from './components/Login'
import Signup from './components/Signup'
import Forgotpassword from './components/Forgotpassword'
import Changepassword from './components/Changepassword'
import Dashboard from './components/Dashboard'
import Surveydetail from './components/Surveydetail'
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';

const App = () => {

  return (
    <>
      <Routes>
        <Route path='/' element={<Start />} />
        <Route path='/Aibar' element={<Aibar />} />
        <Route path='/landing' element={<Landing />} />
        <Route path='/signup/:id' element={<Signup />} />
        <Route path='/forgotpassword/:id' element={<Forgotpassword />} />
        <Route path='/changepassword' element={<Changepassword />} />
        <Route path='/login/:id' element={<Login />} />
        <Route path='/info/:id' element={<Info />} />
        <Route path='/typeform/:id' element={<Typeform />} />
        <Route path='/resumeform/:surveyid/:sheetid' element={<Resumeform />} />
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/surveydetail/:id' element={<Surveydetail />} />
        <Route path="/thankyou" element={<ThankYou />} />
        <Route path="*" element={<PagenotFound />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{ color: "#000" }}
      />
    </>
  )
}

export default App
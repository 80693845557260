import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import { getData, addData, SendNotification } from "../utils/index";


let sheetData;
let userData;

const Signup = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)
    const [pageloader, setPageLoader] = useState(true)
    let { id } = useParams();

    sheetData = JSON.parse(localStorage.getItem('sheetdata'));
    userData = JSON.parse(localStorage.getItem('userdata'));

    useEffect(() => {
        async function sheet() {
            let rows = await getData(process.env.REACT_APP_GOOGLE_SHEET_ID, 0);
            let filteredArray = rows.filter(row => row.value.indexOf(id) > -1);
            if (filteredArray[0].status.trim() === "inactive") {
                SendNotification({ type: 'e', message: 'This beer is inactive.' });
                navigate(`/`);
                return false;
            }
            let sheet = {};
            sheet.label = filteredArray[0].label;
            sheet.value = filteredArray[0].value;
            sheet.status = filteredArray[0].status;
            sheet.aiflag = filteredArray[0].aiflag;
            sheet.what_to_expect = filteredArray[0].what_to_expect;
            sheet.surveyid = uuidv4();
            localStorage.setItem('sheetdata', JSON.stringify(sheet));
            if (userData != null) {
                if (userData.usertype === "registered") {
                    let surveyData = {};
                    surveyData.email = userData.email;
                    surveyData.sheetid = sheet.value;
                    surveyData.status = 'pending';
                    surveyData.usertype = 'registered';
                    surveyData.aiflag = sheet.aiflag;
                    surveyData.sheetname = sheet.label;
                    surveyData.surveyid = sheet.surveyid;
                    surveyData.datetime = moment.tz(new Date(), "GMT").format("YYYY-MM-DD HH:mm:ss");
                    // Add Suvery Data
                    await addData(process.env.REACT_APP_GOOGLE_SHEET_ID, 2, surveyData);
                    let body = {};
                    body.email = userData.email;
                    body.id = sheet.surveyid;
                    body.Timezone = surveyData.datetime;
                    await addData(id, 1, body);
                    if (sheet.aiflag === "TRUE") {
                        setPageLoader(false);
                        navigate(`/typeform/${id}`);
                    } else {
                        setPageLoader(false);
                        navigate(`/info/${id}`);
                    }
                } else {
                    setPageLoader(false);
                }
            } else {
                setPageLoader(false);
            }
        }
        // if (sheetData == null) {
        sheet();
        // }
    }, [id, navigate]);

    const handleSubmit = async (e) => {
        setLoader(true)
        e.preventDefault(); // prevent page refresh
        let sheetData = JSON.parse(localStorage.getItem('sheetdata'));
        let userData = {};
        let email = e.target.email.value;
        let password = e.target.password.value;
        let sheetId = sheetData.value;
        let sheetName = sheetData.label;
        let aiFlag = sheetData.aiflag;
        userData.email = email;
        userData.password = password;
        userData.status = "active";
        userData.usertype = "registered";
        userData.datetime = moment.tz(new Date(), "GMT").format("YYYY-MM-DD HH:mm:ss");

        // Get Data User Data
        let rows = await getData(process.env.REACT_APP_GOOGLE_SHEET_ID, 1);
        let filteredArray = rows.filter(row => row.email.indexOf(email) > -1);
        if (filteredArray.length <= 0) {
            await addData(process.env.REACT_APP_GOOGLE_SHEET_ID, 1, userData);

            localStorage.setItem("userdata", JSON.stringify(userData));

            let surveyData = {};
            surveyData.email = email;
            surveyData.sheetid = sheetId;
            surveyData.status = 'pending';
            surveyData.usertype = 'registered';
            surveyData.aiflag = aiFlag;
            surveyData.sheetname = sheetName;
            surveyData.surveyid = sheetData.surveyid;
            surveyData.datetime = moment.tz(new Date(), "GMT").format("YYYY-MM-DD HH:mm:ss");
            // Add Suvery Data
            await addData(process.env.REACT_APP_GOOGLE_SHEET_ID, 2, surveyData);
            let body = {};
            body.email = userData.email;
            body.id = sheetData.surveyid;
            body.Timezone = surveyData.datetime;
            await addData(id, 1, body);
            setLoader(false)
            SendNotification({ type: 's', message: 'Signup successfully' })
            if (aiFlag === "TRUE") {
                navigate(`/typeform/${id}`);
            } else {
                navigate(`/info/${id}`);
            }
        } else {
            setLoader(false)
            SendNotification({ type: 'e', message: 'This account already exists.' })
        }
    }

    const handleClick = async (e) => {
        e.preventDefault(); // prevent page refresh
        let sheetData = JSON.parse(localStorage.getItem('sheetdata'));
        let aiFlag = sheetData.aiflag;
        let userData = {};
        userData.usertype = "guest";
        localStorage.setItem("userdata", JSON.stringify(userData));
        if (aiFlag === "TRUE") {
            navigate(`/typeform/${id}`);
        } else {
            navigate(`/info/${id}`);
        }
    }

    return (
        <>
            {pageloader ?
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div> : ""
            }
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <form id="msform" className="form_wrapper" onSubmit={handleSubmit}>
                            <fieldset className="rounded signup-fieldset">
                                <div className="mb-4 text-center">
                                    <img src="/images/formlogo.png" className="img-fluid text-center form-logo" alt="" />
                                </div>
                                <h2 className="fs-title mb-5">
                                    Create New Account
                                </h2>
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        {/* <div className="input-container">
                                            <input type="email" name="email" required />
                                            <label>Email</label>
                                        </div> */}
                                        <div className="form__group field">
                                            <input type="email" className="form__field" placeholder="Email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" name="email" id="email" required />
                                            <label htmlFor="email" className="form__label">Email</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form__group field">
                                            <input type="password" className="form__field" placeholder="Password" name="password" id="password" required />
                                            <label htmlFor="password" className="form__label">Password</label>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="next rounded btn btn-lg My-Button-see-live px-4 p-2"
                                    type="submit"
                                >
                                    {loader ? "Loading..." : "Sign Up"}
                                </button>
                                <button
                                    className="next rounded btn btn-lg ml-2 signup-skip px-4 p-2"
                                    type="button"
                                    onClick={handleClick}
                                >
                                    Skip
                                </button>
                                <h6 className="signup-bottom-title mt-4">Already have an account? <a href={`/login/${id}`}>Login</a></h6>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signup;
import React, { useEffect, useState } from "react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useNavigate, useParams } from "react-router-dom";
import { getData, addData, SendNotification, sendEmail } from "../utils/index";

const Forgotpassword = () => {
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    let { id } = useParams();

    const handleSubmit = async (e) => {
        setLoader(true);
        e.preventDefault(); // prevent page refresh
        let email = e.target.email.value;

        //check validation of email
        let validRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!email.match(validRegex)) {
            setLoader(false);
            SendNotification({ type: 'e', message: "Please enter valid email address" })
            return false;
        }
        // Get Data User Data
        let rows = await getData(process.env.REACT_APP_GOOGLE_SHEET_ID, 1);
        let filteredArray = rows.filter(row => row.email.indexOf(email) > -1);
        if (filteredArray.length > 0) {
            const userDoc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_SHEET_ID);
            await userDoc.useServiceAccountAuth({
                client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
                private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
                    /\\n/gm,
                    "\n"
                ),
            });
            await userDoc.getInfo();
            const userSheet = userDoc.sheetsByIndex[1];
            const userRows = await userSheet.getRows();
            let userFilteredArray = userRows.filter(async (row, index) => {
                if (row.email === email) {
                    // Create a random password
                    var randomPassword = Math.random().toString(36).slice(-8);
                    let html = `<p>Hi There,</p>
                                <p>We're sorry to hear that you're having trouble logging in. If you've forgotten your password, please use below mentioned temporary password to login to your account</p>
                                <p>Temp Password : <b>${randomPassword}</b></p>
                                <p>You can always change your password from the menu</p>
                                <p>If you did not request a password reset, please ignore this email and rest assured that your account is secure.</p>
                                <p>Thank you for using Survey Form</p>`;
                    let payload = {
                        tomail: email,
                        subject: 'Forgot Password',
                        html: html
                    }
                    let em = sendEmail(payload);
                    userRows[index].password = randomPassword;
                    await userRows[index].save();
                }
            });
            setLoader(false);
            SendNotification({ type: 's', message: 'Your password has been reset successfully' })
            navigate(`/login/${id}`, { replace: true });
        } else {
            setLoader(false)
            SendNotification({ type: 'e', message: 'User not found' })
        }
    };
    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <form id="msform" className="form_wrapper" onSubmit={handleSubmit}>
                            <fieldset className="rounded login-fieldset">
                                <div className="mb-4 text-center">
                                    <img src="/images/formlogo.png" className="img-fluid text-center form-logo" alt="" />
                                </div>
                                <h2 className="fs-title mb-5">
                                    Forgot Password
                                </h2>
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <div className="form__group field">
                                            <input type="email" className="form__field" placeholder="Email" name="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" id="email" required />
                                            <label htmlFor="email" className="form__label">Email</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start">
                                    <button
                                        className="next rounded btn btn-lg My-Button-see-live px-4 p-2"
                                        type="submit"
                                    >
                                        {loader ? "Loading..." : "Reset Password"}
                                    </button>
                                </div>
                                <h6 className="signup-bottom-title mt-4">Already have an account? <a href={`/login/${id}`}>Login</a></h6>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Forgotpassword;
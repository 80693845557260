import React, { useEffect, useState } from "react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useNavigate, useParams } from "react-router-dom";
import { getData, addData, SendNotification, sendEmail } from "../utils/index";

let userData;
let sheetinfo;

const Changepassword = () => {
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        userData = JSON.parse(localStorage.getItem('userdata'));
        sheetinfo = JSON.parse(localStorage.getItem('sheetdata'));
    }, [])

    const [input, setInput] = useState({
        current_password: '',
        new_password: '',
        confirm_password: '',
    });
    const onInputChange = (e) => {
        const { name, value } = e.target;
        setInput((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        let currentPassword = e.target.current_password.value;
        let newPassword = e.target.new_password.value;
        let confirmPassword = e.target.confirm_password.value;
        //Start Password validation
        let error = null;
        if (!currentPassword) {
            error = 'Please enter CurrentPassword.';
        } else if (!newPassword) {
            error = 'Please enter Newpassword.';
        } else if (input.confirm_password && newPassword !== input.confirm_password) {
            error = 'Newpassword and Confirm Password does not match.';
        } else if (!confirmPassword) {
            error = 'Please enter Confirm Password.';
        } else if (input.new_password && confirmPassword !== input.new_password) {
            error = 'Newpassword and Confirm Password does not match.';
        }
        // End Password validation

        if (error == null) {

            // Get Data User Data
            let rows = await getData(process.env.REACT_APP_GOOGLE_SHEET_ID, 1);
            let checkCurrentPassword = rows.filter(row => row.password.indexOf(currentPassword) > -1);
            if (checkCurrentPassword.length > 0) {
                const userDoc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_SHEET_ID);
                await userDoc.useServiceAccountAuth({
                    client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
                    private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
                        /\\n/gm,
                        "\n"
                    ),
                });
                await userDoc.getInfo();
                const userSheet = userDoc.sheetsByIndex[1];
                const userRows = await userSheet.getRows();
                let userFilteredArray = userRows.filter(async (row, index) => {
                    if (row.password === currentPassword) {
                        userRows[index].password = newPassword;
                        await userRows[index].save();
                    }
                });
                setLoader(false);
                SendNotification({ type: 's', message: 'Your password has been change successfully' })
                navigate(`/info/${sheetinfo.value}`, { replace: true });
            } else {
                setLoader(false);
                SendNotification({ type: 'e', message: "Please check current password is wrong." });
                return false;
            }
        } else {
            setLoader(false);
            SendNotification({ type: 'e', message: error });
            return false;
        }

    }

    return (
        <>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <form id="msform" className="form_wrapper" onSubmit={handleSubmit}>
                            <fieldset className="rounded login-fieldset">
                                <div className="mb-4 text-center">
                                    <img src="/images/formlogo.png" className="img-fluid text-center form-logo" alt="" />
                                </div>
                                <h2 className="fs-title mb-5">
                                    Change Password
                                </h2>
                                <div className="row mb-4">
                                    <div className="col-md-12">
                                        <div className="form__group field">
                                            <input type="password" className="form__field" placeholder="Current Password" name="current_password" id="current_password" onChange={onInputChange} required />
                                            <label htmlFor="current_password" className="form__label">Current Password</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form__group field">
                                            <input type="password" className="form__field" placeholder="New Password" name="new_password" id="new_password" onChange={onInputChange} required />
                                            <label htmlFor="new_password" className="form__label">New Password</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form__group field">
                                            <input type="password" className="form__field" placeholder="Confirm Password" name="confirm_password" id="confirm_password" onChange={onInputChange} required />
                                            <label htmlFor="confirm_password" className="form__label">Confirm Password</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-start">
                                    <button
                                        className="next rounded btn btn-lg My-Button-see-live px-4 p-2"
                                        type="submit"
                                    >
                                        {loader ? "Loading..." : "Submit"}
                                    </button>
                                </div>
                                {/* <h6 className="signup-bottom-title mt-4">Already have an account? <a href={`/login/${id}`}>Login</a></h6> */}
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Changepassword;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGoogleSheets from "use-google-sheets";
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment-timezone';
import { getData, addData, SendNotification } from "../utils/index";
import Mymodalpopup from "../utils/Modalpopup";
import Header from "../utils/Header";

let userData;
let sheetinfo;

const Info = () => {
    const [loader, setLoader] = useState(false)
    const [pageloader, setPageLoader] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [sheetData, setSheetData] = useState([]);

    const navigate = useNavigate();
    let { id } = useParams();

    useEffect(() => {
        userData = JSON.parse(localStorage.getItem('userdata'));
        sheetinfo = JSON.parse(localStorage.getItem('sheetdata'));
    }, [])


    useEffect(() => {
        async function sheet() {

            let surveyRows = await getData(process.env.REACT_APP_GOOGLE_SHEET_ID, 2);
            let pendingSurveyfilter = surveyRows.filter(row =>
                row.email === userData.email && row.sheetid !== id && row.status === "pending" && row.usertype === "registered"
            );
            if (pendingSurveyfilter.length > 0) {
                setShowModal(true);
            }
            let currentSurveyfilter = surveyRows.filter(row =>
                row.email === userData.email && row.sheetid === id && row.status === "pending" && row.usertype === userData.usertype
            );
            let rows = await getData(process.env.REACT_APP_GOOGLE_SHEET_ID, 0);
            let filteredArray = rows.filter(row => row.value.indexOf(id) > -1);
            if (filteredArray[0].status.trim() === "inactive") {
                SendNotification({ type: 'e', message: 'This beer is inactive.' });
                navigate(`/`);
                return false;
            }
            let sheet = {};
            sheet.label = filteredArray[0].label;
            sheet.value = filteredArray[0].value;
            sheet.status = filteredArray[0].status;
            sheet.aiflag = filteredArray[0].aiflag;
            sheet.what_to_expect = filteredArray[0].what_to_expect;
            if (currentSurveyfilter.length !== 0) {
                sheet.surveyid = currentSurveyfilter[0].surveyid;
            } else {
                sheet.surveyid = uuidv4();
            }
            localStorage.setItem('sheetdata', JSON.stringify(sheet));
            if (userData != null) {
                if (userData.usertype === "registered") {
                    if (currentSurveyfilter.length === 0) {
                        let surveyData = {};
                        surveyData.email = userData.email;
                        surveyData.sheetid = sheet.value;
                        surveyData.status = 'pending';
                        surveyData.usertype = 'registered';
                        surveyData.aiflag = sheet.aiflag;
                        surveyData.sheetname = sheet.label;
                        surveyData.surveyid = sheet.surveyid;
                        surveyData.datetime = moment.tz(new Date(), "GMT").format("YYYY-MM-DD HH:mm:ss");

                        // Add Suvery Data
                        await addData(process.env.REACT_APP_GOOGLE_SHEET_ID, 2, surveyData);
                        let body = {};
                        body.email = userData.email;
                        body.id = sheet.surveyid;
                        body.Timezone = surveyData.datetime;
                        await addData(id, 1, body);
                    }
                    if (sheet.aiflag === "TRUE") {
                        setPageLoader(false);
                        navigate(`/typeform/${id}`);
                    } else {
                        setPageLoader(false);
                    }
                } else {
                    setPageLoader(false);
                }
            } else {
                setPageLoader(false);
                SendNotification({ type: 'e', message: 'User not found' });
                navigate(`/`);
                return false;
            }
        }
        // if (sheetData == null) {
        sheet();
        // }
    }, [id]);

    const handleSubmit = async (event) => {
        setLoader(true)
        event.preventDefault(); // prevent page refresh
        // do something with the form data (e.g. submit it to a server)
        // navigate(`/login/${id}`);");
        if (sheetData[0].data.length > 0) {
            setLoader(false)
            navigate(`/typeform/${id}`);
        } else {
            setLoader(false)
            SendNotification({ type: 'e', message: 'Question not found' })
        }
    };

    const {
        data,
        loading,
        error
    } = useGoogleSheets({
        apiKey: "AIzaSyCJe7gwYPS9NoJ-muWbr-23izLdNcaR3hA",
        sheetId: id,
    });
    useEffect(() => {
        setSheetData(data);
    }, [data]);

    const handleContinue = () => {
        setShowModal(false);
        navigate(`/dashboard`);

    }
    const handleSkip = () => {
        setShowModal(false);
    }
    return (
        <>
            <Header></Header>
            {pageloader ?
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div> : ""
            }
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <form id="msform" className="form_wrapper" onSubmit={handleSubmit}>
                            <fieldset className="rounded">
                                <div className="mb-4 text-center">
                                    <img src="/images/formlogo.png" className="img-fluid text-center form-logo" alt="" />
                                </div>
                                <h2 className="fs-title">
                                    What to expect from the {sheetinfo?.label}
                                </h2>
                                <h4 className="info-sub-title mb-4">{sheetinfo?.what_to_expect}</h4>
                                <button
                                    className="next rounded btn btn-lg My-Button-see-live px-4 p-2"
                                    type="submit"
                                >
                                    {loader ? "Loading..." : "Continue"}
                                </button>
                            </fieldset>
                        </form>
                        <Mymodalpopup show={showModal} onSave={handleContinue} onClose={handleSkip} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Info;
import React from "react";
import { useNavigate } from "react-router-dom";

const Start = () => {
  const navigate = useNavigate();
  // localStorage.clear();
  localStorage.removeItem("sheetdata");
  localStorage.removeItem("barcodelink");
  const onstart = () => {
    navigate("/landing");
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8">
            <div className="form_wrapper position-relative flex-column">
              <div className="rounded start_page">
                <div className="row justify-content-center">
                  <div className="col-xl-6 col-12">
                    <img src="images/logo.png" className="img-fluid" alt="" />
                  </div>
                  <div className="col-xl-10">
                    <h3 className="mt-md-6 mt-2">
                      Collaborate with brewers and help us to democratize
                      Artificial intelligence!
                    </h3>
                  </div>
                  <div className="col-xl-9">
                    <h3 className="mt-md-6 mt-4">
                      Your feedback, coupled with the power of AI will connect
                      you directly to the brewer to help improve this beer.
                    </h3>
                  </div>
                  <div className="col-xl-8">
                    <h3 className="my-md-6 my-4">Cheers!</h3>
                  </div>
                </div>
                <button
                  type="button"
                  name="next"
                  className="next rounded btn btn-lg My-Button-see-live px-4 p-2"
                  onClick={() => onstart()}
                >
                  Start
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Start;

import React, { useEffect, useState } from "react";
import useGoogleSheets from "use-google-sheets";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { v4 as uuidv4 } from 'uuid';
import { getData, addData, SendNotification, getHeader, score_to_volume, sendEmail, new_score_to_volume } from "../utils/index";
import Header from "../utils/Header";
import { Field } from "react-final-form";
import Wizard from "./Wizard";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Slider from "@mui/material/Slider";
import moment from 'moment-timezone';


let userData = JSON.parse(localStorage.getItem('userdata'));

const Resumeform = () => {
    const [formData, setFormData] = useState(null);
    const [sheetData, setSheetData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [loader, setLoader] = useState(false);
    const [pageloader, setPageLoader] = useState(true)
    const [page, setPage] = useState(null)

    let { surveyid } = useParams();
    let { sheetid } = useParams();

    const navigate = useNavigate();
    useEffect(() => {
        const data = window.localStorage.getItem('userdata');
        if (data !== null) setUserData(JSON.parse(data));
    }, []);

    useEffect(() => {
        async function sheet() {
            let questions = await getData(sheetid, 0);
            if (userData !== null && userData.usertype === "guest") {
                let guest = { Question: "Thanks for getting involved and tasting the future with us! If you would like to receive news and updates about the T(AI)STE bar and AI beverages, please leave your email below!", Type: "input", usertype: "guest" };
                questions.push(guest);
            }
            setFormData(questions);

            let answer = await getData(sheetid, 1);
            let answerDetail = answer.filter(row =>
                row.id === surveyid
            );
            let ansVal = Object.values(answerDetail[0])[2];
            for (var i = 0; i < Object.keys(questions).length; i++) {
                if (ansVal[i] === "") {
                    setPage(i);
                    break;
                }
            }
            let sheetDetails = await getData(process.env.REACT_APP_GOOGLE_SHEET_ID, 0);
            let sheetDetail = sheetDetails.filter(row =>
                row.value === sheetid
            );
            let sheet = {};
            sheet.label = sheetDetail[0].label;
            sheet.value = sheetDetail[0].value;
            sheet.status = sheetDetail[0].status;
            sheet.aiflag = sheetDetail[0].aiflag;
            sheet.what_to_expect = sheetDetail[0].what_to_expect;
            sheet.surveyid = surveyid;
            setSheetData(sheet);
            localStorage.setItem('sheetdata', JSON.stringify(sheet));
            setPageLoader(false);
        }
        sheet();
    }, [surveyid, sheetid, userData]);

    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    const onSubmit = async (values) => {
        setLoader(true);

        let body = {};
        for (const property in values) {
            if (typeof values[property] === "object") {
                body[property] = values[property].value;
            } else {
                body[property] = values[property];
            }
        }
        body.id = null;
        body.Timezone = moment.tz(new Date(), "GMT").format("YYYY-MM-DD HH:mm:ss");
        if (userData.usertype === 'registered') {
            body.email = userData.email;
        }

        //Start Generate Barcode
        if (sheetData.aiflag === "TRUE") {
            // let glassVolume = '0280';

            delete values.email;
            // check if glass volume is
            let glassVolume;
            // let baseScore;
            let questions = await getData(sheetid, 0);
            let queLength = questions.length;
            if (Object.keys(values).includes(`Q${queLength}`) === false) {
                let opt = questions.pop().Option.split(",");
                glassVolume = Math.min(...opt);
                // baseScore = Object.values(values);
                let ke = 'Q' + queLength;
                Object.assign(body, { [ke]: glassVolume });
            } else {
                glassVolume = values[Object.keys(values).pop()];
                // baseScore = Object.values(values).slice(0, -1);
            }
            //end  check if glass volume is

            // let glassVolume = '0' + values[Object.keys(values).pop()];

            let answerData = await getData(sheetid, 1);
            let findAns = answerData.filter(row => row.id === surveyid);
            // let baseScore = Object.values(values).slice(0, -1);
            let baseScore = Object.values(findAns[0])[2].slice(0, 4);
            // let newScore = new_score_to_volume(baseScore[0], baseScore[1], baseScore[2], baseScore[3], baseScore[4], baseScore[5]);
            let newScore = score_to_volume(0, 0, baseScore[0], baseScore[1], baseScore[2], baseScore[3]);

            // let removeComma = newScore.replace(/,/g, "");
            // let baseTotal = Object.values(values).slice(0, -1).reduce((a, b) => a + b, 0);
            // let newBase = Object.values(values).slice(0, -1).map(function (value, label) {
            //     return (value / baseTotal).toFixed(2).split(".").pop();
            // }).reduce((a, b) => a + b);
            // let barcode = (glassVolume + "," + newScore.join(","));
            let barcode = (glassVolume + "," + newScore);
            var barcodeLink = `https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=${barcode}`;
            body.qrcode = barcodeLink;
            localStorage.setItem("barcodelink", barcodeLink);
        }
        // End Genearte Barcode

        await sleep(300);
        // return false;
        let ans = Object.keys(body);
        let answerHeader = await getHeader(sheetid, 1);
        let head = Object.values(answerHeader);
        const checkData = ans.every(r => head.includes(r));
        // check data mismatch
        if (checkData === true) {
            // Start Surveys Doc Update
            if (userData.usertype === 'registered') {
                const surveysDoc = new GoogleSpreadsheet(process.env.REACT_APP_GOOGLE_SHEET_ID);
                await surveysDoc.useServiceAccountAuth({
                    client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
                    private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
                        /\\n/gm,
                        "\n"
                    ),
                });
                await surveysDoc.getInfo();
                const surveysSheet = surveysDoc.sheetsByIndex[2];
                const surveysRows = await surveysSheet.getRows();
                let filteredArray = surveysRows.filter(async (row, index) => {
                    if (row.surveyid === surveyid) {
                        body.id = row.surveyid;
                        surveysRows[index].status = 'complete';
                        surveysRows[index].datetime = moment.tz(new Date(), "GMT").format("YYYY-MM-DD HH:mm:ss");
                        if (sheetData.aiflag === "TRUE") {
                            surveysRows[index].qrcode = barcodeLink;
                        }
                        await surveysRows[index].save();
                    }
                });
            }
            const answerDoc = new GoogleSpreadsheet(sheetid);
            await answerDoc.useServiceAccountAuth({
                client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
                private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
                    /\\n/gm,
                    "\n"
                ),
            });
            await answerDoc.getInfo();
            const answerSheet = answerDoc.sheetsByIndex[1];
            const answerRows = await answerSheet.getRows();
            let findPendingAns = answerRows.filter(async (row, index) => {
                if (row.id === surveyid) {
                    Object.assign(row, body);
                    let payload = {
                        tomail: "snehatpm1@gmail.com",
                        subject: 'Answers Log',
                        html: `<h4>Answer : ${JSON.stringify(body)}</h4>`
                    }
                    let em = sendEmail(payload);
                    await answerRows[index].save();
                }
            });
            // await addData(sheetid, 1, body);
            // End Surveys Doc Update
            setLoader(false);
            SendNotification({ type: 's', message: 'Survey submit successfully' })
            navigate("/thankyou", { replace: true });
        } else {
            SendNotification({ type: 'e', message: 'Error encountered while submitting a form.' })
            setLoader(false);
            return false;
        }
    }
    // Start Per page value saved data
    const onClick = async (values) => {
        let body = {};
        for (const property in values) {
            if (typeof values[property] === "object") {
                body[property] = values[property].value;
            } else {
                body[property] = values[property];
            }
        }
        const answersDoc = new GoogleSpreadsheet(sheetid);
        await answersDoc.useServiceAccountAuth({
            client_email: process.env.REACT_APP_GOOGLE_SERVICE_ACCOUNT_EMAIL,
            private_key: process.env.REACT_APP_GOOGLE_PRIVATE_KEY.replace(
                /\\n/gm,
                "\n"
            ),
        });
        await answersDoc.getInfo();
        const answerSheet = answersDoc.sheetsByIndex[1];
        const answerRows = await answerSheet.getRows();
        let filteredArray = answerRows.filter(async (row, index) => {
            // if (row.email.indexOf(userData.email) > -1 && row.sheetid.indexOf(id) > -1 && row.status.indexOf('pending') > -1) {
            if (row.id === surveyid) {
                Object.assign(row, body);
                await answerRows[index].save();
            }
        });
    };
    // End Per page value saved data
    return (
        <>
            {pageloader ?
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div> : ""
            }
            <Header></Header>
            <div className="container-fluid">
                {page !== null &&
                    <Wizard loader={loader} page={page} onSubmit={onSubmit} onClick={onClick}>
                        {formData !== null && sheetData != null &&
                            formData.map((item, index) => {
                                if (item.Type === "info") {
                                    if (sheetData?.aiflag === "TRUE") {
                                        return (
                                            <Wizard.Page key={index}>
                                                <div className="text-center">
                                                    <h2 className="fs-title">
                                                        Base {index + 1} → &nbsp;{item.Question}
                                                    </h2>
                                                    {item?.Subheading && (
                                                        <h4 className="sub-title fs-6 mx-auto">{item?.Subheading}</h4>
                                                    )}
                                                    {item?.Note && (
                                                        <h4 className="sub-title col-7 mx-auto mb-4 fs-6">{item?.Note}</h4>
                                                    )}
                                                </div>
                                                {item?.Option && <p className="fs-info">{item?.Option}</p>}
                                            </Wizard.Page>
                                        );
                                    } else {
                                        return (
                                            <Wizard.Page key={index}>
                                                <h2 className="fs-title">
                                                    {index + 1} → &nbsp;{item.Question}
                                                </h2>
                                                {item?.Subheading && (
                                                    <h4 className="sub-title">{item?.Subheading}</h4>
                                                )}
                                                {item?.Option && <p className="fs-info">{item?.Option}</p>}
                                            </Wizard.Page>
                                        );
                                    }
                                } else if (item.Type === "input") {
                                    if (sheetData?.aiflag === "TRUE") {
                                        if (item.usertype && item.usertype === 'guest') {
                                            return (
                                                <Wizard.Page key={index}>
                                                    <div className="text-center">
                                                        <h2 className="fs-title">
                                                            Base {index + 1} → &nbsp;{item.Question}
                                                        </h2>
                                                        {item?.Subheading && (
                                                            <h4 className="sub-title fs-6 mx-auto">{item?.Subheading}</h4>
                                                        )}
                                                        {item?.Note && (
                                                            <h4 className="sub-title col-7 mx-auto mb-4 fs-6">{item?.Note}</h4>
                                                        )}
                                                    </div>
                                                    <Field
                                                        name={`email`}
                                                        component="input"
                                                        type="text"
                                                        className="inputing mt-4"
                                                        placeholder="Enter Your Email"
                                                    // validate={required}
                                                    />
                                                    {/* <Error name="email" /> */}
                                                </Wizard.Page>
                                            );
                                        } else {
                                            return (
                                                <Wizard.Page key={index}>
                                                    <div className="text-center">
                                                        <h2 className="fs-title">
                                                            Base {index + 1} → &nbsp;{item.Question}
                                                        </h2>
                                                        {item?.Subheading && (
                                                            <h4 className="sub-title fs-6 mx-auto">{item?.Subheading}</h4>
                                                        )}
                                                        {item?.Note && (
                                                            <h4 className="sub-title col-7 mx-auto mb-4 fs-6">{item?.Note}</h4>
                                                        )}
                                                    </div>
                                                    <Field
                                                        name={`Q${index + 1}`}
                                                        component="input"
                                                        type="text"
                                                        className="inputing"
                                                    />
                                                    {/* <Error name="firstName" /> */}
                                                </Wizard.Page>
                                            );
                                        }
                                    } else {
                                        if (item.usertype && item.usertype === 'guest') {
                                            return (
                                                <Wizard.Page key={index}>
                                                    <h2 className="fs-title">
                                                        {index + 1} → &nbsp;{item.Question}
                                                    </h2>
                                                    {item?.Subheading && (
                                                        <h4 className="sub-title">{item?.Subheading}</h4>
                                                    )}
                                                    <Field
                                                        name={`email`}
                                                        component="input"
                                                        type="text"
                                                        className="inputing mt-4"
                                                        placeholder="Enter Your Email"
                                                    // validate={required}
                                                    />
                                                    {/* <Error name="email" /> */}
                                                </Wizard.Page>
                                            );
                                        } else {
                                            return (
                                                <Wizard.Page key={index}>
                                                    <h2 className="fs-title">
                                                        {index + 1} → &nbsp;{item.Question}
                                                    </h2>
                                                    {item?.Subheading && (
                                                        <h4 className="sub-title">{item?.Subheading}</h4>
                                                    )}
                                                    <Field
                                                        name={`Q${index + 1}`}
                                                        component="input"
                                                        type="text"
                                                        className="inputing"
                                                    />
                                                    {/* <Error name="firstName" /> */}
                                                </Wizard.Page>
                                            );
                                        }
                                    }
                                } else if (item.Type === "radio") {
                                    if (sheetData?.aiflag === "TRUE") {
                                        return (
                                            <Wizard.Page key={index}>
                                                <div className="text-center">
                                                    <h2 className="fs-title">
                                                        Base {index + 1} → &nbsp;{item.Question}
                                                    </h2>
                                                    {item?.Subheading && (
                                                        <h4 className="sub-title fs-6 mx-auto">{item?.Subheading}</h4>
                                                    )}
                                                    {item?.Note && (
                                                        <h4 className="sub-title col-7 mx-auto mb-4 fs-6">{item?.Note}</h4>
                                                    )}
                                                </div>
                                                <div className="radio-list-left d-md-flex justify-content-around">
                                                    {item?.Option &&
                                                        item?.Option.split(",").map((val, inex) => (
                                                            <dd key={inex}>
                                                                <Field
                                                                    name={`Q${index + 1}`}
                                                                    component="input"
                                                                    type="radio"
                                                                    id={`pq${inex}-${inex}`}
                                                                    value={val}
                                                                />
                                                                <label htmlFor={`pq${inex}-${inex}`}>{val}ML</label>
                                                            </dd>
                                                        ))}
                                                </div>
                                            </Wizard.Page>
                                        );
                                    } else {
                                        return (
                                            <Wizard.Page key={index}>
                                                <h2 className="fs-title">
                                                    {index + 1} → &nbsp;{item.Question}
                                                </h2>
                                                {item?.Subheading && (
                                                    <h4 className="sub-title">{item?.Subheading}</h4>
                                                )}
                                                <div className="radio-list-left">
                                                    {item?.Option &&
                                                        item?.Option.split(",").map((val, inex) => (
                                                            <dd key={inex}>
                                                                <Field
                                                                    name={`Q${index + 1}`}
                                                                    component="input"
                                                                    type="radio"
                                                                    id={`pq${inex}-${inex}`}
                                                                    value={val}
                                                                />
                                                                <label htmlFor={`pq${inex}-${inex}`}>{val}</label>
                                                            </dd>
                                                        ))}
                                                </div>
                                            </Wizard.Page>
                                        );
                                    }
                                } else if (item.Type === "range") {
                                    if (sheetData?.aiflag === "TRUE") {
                                        return (
                                            <Wizard.Page key={index}>
                                                <div className="text-center">
                                                    <h2 className="fs-title">
                                                        Base {index + 1} → &nbsp;{item.Question}
                                                    </h2>
                                                    {item?.Subheading && (
                                                        <h4 className="sub-title fs-6 mx-auto">{item?.Subheading}</h4>
                                                    )}
                                                    {item?.Note && (
                                                        <h4 className="sub-title col-7 mx-auto mb-4 fs-6">{item?.Note}</h4>
                                                    )}
                                                </div>
                                                <div className="row align-items-end mb-4">
                                                    <div className="col-xl-2 col-0"></div>
                                                    <div className="col-xl-12 col-12">
                                                        <div className="d-flex justify-content-between align-items-baseline">
                                                            {item?.Heading &&
                                                                item?.Heading.split(",")
                                                                    .filter(function (el) {
                                                                        return el != "";
                                                                    })
                                                                    .map((val, index) => (
                                                                        <h3 key={index} className="color_font">
                                                                            {val}
                                                                        </h3>
                                                                    ))}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-0"></div>

                                                    <div className="col-xl-12 col-12">
                                                        <div className="d-flex justify-content-between align-items-baseline">
                                                            <h3 className="color_font">0</h3>
                                                            <h3 className="color_font">5</h3>
                                                            <h3 className="color_font">10</h3>
                                                        </div>
                                                    </div>
                                                    {item?.Option &&
                                                        item?.Option.split(",")
                                                            .filter(function (el) {
                                                                return el != "";
                                                            })
                                                            .map((val, ind) => {
                                                                return (
                                                                    <React.Fragment key={ind}>
                                                                        {/* <div className="col-xl-2 mt-md-0 mt-md-4 mt-0">
                                    <h3 className="color_font text-start">{val}</h3>
                                  </div> */}
                                                                        <div className="col-xl-12">
                                                                            <Field
                                                                                name={`Q${index + 1}-${val}`}
                                                                                initialValue={5}
                                                                            >
                                                                                {(props) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Slider
                                                                                                name={`Q${index + 1}-${val}`}
                                                                                                defaultValue={5}
                                                                                                type="number"
                                                                                                aria-label="Default"
                                                                                                valueLabelDisplay="auto"
                                                                                                step={1}
                                                                                                min={0}
                                                                                                max={10}
                                                                                                onChange={props.input.onChange}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                }}
                                                                            </Field>
                                                                        </div>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                </div>
                                            </Wizard.Page>
                                        );
                                    } else {
                                        return (
                                            <Wizard.Page key={index}>
                                                <h2 className="fs-title">
                                                    {index + 1} → &nbsp;{item.Question}
                                                </h2>
                                                {item?.Subheading && (
                                                    <h4 className="sub-title">{item?.Subheading}</h4>
                                                )}
                                                <div className="row align-items-end mb-4">
                                                    <div className="col-xl-2 col-0"></div>
                                                    <div className="col-xl-10 col-12">
                                                        <div className="d-flex justify-content-between align-items-baseline">
                                                            {item?.Heading &&
                                                                item?.Heading.split(",")
                                                                    .filter(function (el) {
                                                                        return el != "";
                                                                    })
                                                                    .map((val, index) => (
                                                                        <h3 key={index} className="color_font">
                                                                            {val}
                                                                        </h3>
                                                                    ))}
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2 col-0"></div>

                                                    <div className="col-xl-10 col-12">
                                                        <div className="d-flex justify-content-between align-items-baseline">
                                                            <h3 className="color_font">-10</h3>
                                                            <h3 className="color_font">0</h3>
                                                            <h3 className="color_font">10</h3>
                                                        </div>
                                                    </div>
                                                    {item?.Option &&
                                                        item?.Option.split(",")
                                                            .filter(function (el) {
                                                                return el != "";
                                                            })
                                                            .map((val, ind) => {
                                                                return (
                                                                    <React.Fragment key={ind}>
                                                                        <div className="col-xl-2 mt-md-0 mt-md-4 mt-0">
                                                                            <h3 className="color_font text-start">{val}</h3>
                                                                        </div>
                                                                        <div className="col-xl-10">
                                                                            <Field
                                                                                name={`Q${index + 1}-${val}`}
                                                                                initialValue={0}
                                                                            >
                                                                                {(props) => {
                                                                                    return (
                                                                                        <>
                                                                                            <Slider
                                                                                                name={`Q${index + 1}-${val}`}
                                                                                                defaultValue={0}
                                                                                                type="number"
                                                                                                aria-label="Default"
                                                                                                valueLabelDisplay="auto"
                                                                                                step={1}
                                                                                                min={-10}
                                                                                                max={10}
                                                                                                onChange={props.input.onChange}
                                                                                            />
                                                                                        </>
                                                                                    );
                                                                                }}
                                                                            </Field>
                                                                        </div>
                                                                    </React.Fragment>
                                                                );
                                                            })}
                                                </div>
                                            </Wizard.Page>
                                        );
                                    }
                                } else if (item.Type === "dropdown") {
                                    if (sheetData?.aiflag === "TRUE") {
                                        return (
                                            <Wizard.Page key={index}>
                                                <div className="text-center">
                                                    <h2 className="fs-title">
                                                        Base {index + 1} → &nbsp;{item.Question}
                                                    </h2>
                                                    {item?.Subheading && (
                                                        <h4 className="sub-title fs-6 mx-auto">{item?.Subheading}</h4>
                                                    )}
                                                    {item?.Note && (
                                                        <h4 className="sub-title col-7 mx-auto mb-4 fs-6">{item?.Note}</h4>
                                                    )}
                                                </div>
                                                <Field name={`Q${index + 1}`}>
                                                    {(props) => (
                                                        <Select
                                                            value={props.input.value}
                                                            onChange={props.input.onChange}
                                                            className="inputing"
                                                            defaultValue={selectedOption}
                                                            // onChange={setSelectedOption}
                                                            options={item?.Option.split("|").map((str, index) =>
                                                                JSON.parse(str)
                                                            )}
                                                        />
                                                    )}
                                                </Field>
                                            </Wizard.Page>
                                        );
                                    } else {
                                        return (
                                            <Wizard.Page key={index}>
                                                <h2 className="fs-title">
                                                    {index + 1} → &nbsp;{item.Question}
                                                </h2>
                                                {item?.Subheading && (
                                                    <h4 className="sub-title">{item?.Subheading}</h4>
                                                )}
                                                <Field name={`Q${index + 1}`}>
                                                    {(props) => (
                                                        <Select
                                                            value={props.input.value}
                                                            onChange={props.input.onChange}
                                                            className="inputing"
                                                            defaultValue={selectedOption}
                                                            // onChange={setSelectedOption}
                                                            options={item?.Option.split("|").map((str, index) =>
                                                                JSON.parse(str)
                                                            )}
                                                        />
                                                    )}
                                                </Field>
                                            </Wizard.Page>
                                        );
                                    }
                                }
                            })}
                    </Wizard>
                }
            </div>
        </>
    );
}

export default Resumeform;

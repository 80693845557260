import React, { useEffect, useState } from "react";
import useGoogleSheets from "use-google-sheets";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { SendNotification } from "../utils/index";

const Landing = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [masterSheets, setMasterSheets] = useState([]);
    let userData = JSON.parse(localStorage.getItem('userdata'));

    const navigate = useNavigate();
    const handleSubmit = (event) => {
        event.preventDefault(); // prevent page refresh
        // do something with the form data (e.g. submit it to a server)
        if (selectedOption != null) {
            localStorage.setItem('sheetdata', JSON.stringify(selectedOption));
            let sheetId = selectedOption.value;
            if (userData != null) {
                if (userData.usertype === "guest") {
                    navigate(`/signup/${sheetId}`);
                } else {
                    navigate(`/info/${sheetId}`);
                }
            } else {
                navigate(`/signup/${sheetId}`);
            }
        } else {
            SendNotification({ type: 'e', message: 'Please select a beer' })
        }
    };

    const {
        data = [],
        loading
    } = useGoogleSheets({
        apiKey: "AIzaSyCJe7gwYPS9NoJ-muWbr-23izLdNcaR3hA",
        sheetId: process.env.REACT_APP_GOOGLE_SHEET_ID,
    });

    useEffect(() => {
        if (data.length > 0 && data[0].data) {
            let filteredArray = data[0].data.map((row, index) => {
                if (row.status === 'active' && row.aiflag === 'FALSE') {
                    return row;
                }
            }).filter(function (element) {
                return element !== undefined;
            });
            setMasterSheets(filteredArray);
        }
    }, [data]);


    return (
        <>
            {masterSheets.length > 0 &&
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-8">
                            <form id="msform" className="form_wrapper" onSubmit={handleSubmit}>
                                <fieldset className="rounded">
                                    <div className="mb-4 text-center">
                                        <img src="/images/formlogo.png" className="img-fluid text-center form-logo" alt="" />
                                    </div>
                                    <h2 className="fs-title">
                                        Which beer are you drinking?
                                    </h2>
                                    <Select
                                        className="inputing"
                                        onChange={setSelectedOption}
                                        options={masterSheets}
                                        name="beer"
                                    />
                                    <button
                                        className="next rounded btn btn-lg My-Button-see-live px-4 p-2 h2"
                                        type="submit"
                                    >
                                        Next
                                    </button>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default Landing;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../utils/Header";
import moment from 'moment-timezone';
import { getData, addData, SendNotification } from "../utils/index";

let sheetData = JSON.parse(localStorage.getItem('sheetdata'));
let userData;


const Dashboard = () => {
    const navigate = useNavigate();
    const [pageloader, setPageLoader] = useState(false)
    const [pendingSurvey, setPendingSurvey] = useState([])
    const [completeSurvey, setCompleteSurvey] = useState([])

    useEffect(() => {
        userData = JSON.parse(localStorage.getItem('userdata'));
    }, []);

    async function sheet() {
        if (userData.usertype !== "registered") {
            navigate(`/`);
            return false;
        }
        let rows = await getData(process.env.REACT_APP_GOOGLE_SHEET_ID, 2);
        let pendingSurveyfilter = rows.filter(row =>
            row.email === userData.email && row.status === "pending" && row.usertype === "registered"
        );
        let compeletedSurvey = rows.filter(row =>
            row.email === userData.email && row.status === "complete" && row.usertype === "registered"
        );
        setPendingSurvey(pendingSurveyfilter);
        setCompleteSurvey(compeletedSurvey);
    }

    useEffect(() => {
        sheet();
    }, [userData]);


    const handleClick = (surveyId) => {
        navigate(`/surveydetail/${surveyId}`);
    };

    const resumeClick = (surveyId, sheetId) => {
        navigate(`/resumeform/${surveyId}/${sheetId}`);
    };

    return (
        <>
            {pageloader ?
                <div className="spinner-container">
                    <div className="loading-spinner"></div>
                </div> : ""
            }
            <Header></Header>
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        <div className=" position-relative flex-column">
                            <div className="rounded start_page">
                                <div className="row justify-content-center mb-5 mt-3">
                                    <div className="col-xl-6 col-12">
                                        <img src="images/formlogo.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div className="row justify-content-around surveys_wrapper">

                                    <div className="col-xl-5 text-start">
                                        <h2>Incomplete Surveys</h2>
                                        <div className="survey_scroll">
                                            {pendingSurvey.length > 0 && pendingSurvey.map((pending, index) => {
                                                return (
                                                    <>
                                                        <div className="d-flex justify-content-between incomplete_wrepper mt-5 pb-4">
                                                            <div className="d-flex">
                                                                <p className="me-3">{index + 1}</p>
                                                                <h5 className="mb-0">{pending.sheetname}<br /><span>{moment(pending.datetime).format('ll')}</span></h5>

                                                            </div>
                                                            <button type="button" name="next" className="next rounded btn survey_btn text-white" onClick={(e) => resumeClick(pending.surveyid, pending.sheetid)}>Resume</button>
                                                        </div>
                                                    </>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="col-xl-5 text-start">
                                        <h2>Completed Surveys</h2>
                                        <div className="survey_scroll">
                                            {completeSurvey.length > 0 && completeSurvey.map((complete, index) => {
                                                return (
                                                    <>
                                                        <div className="d-flex justify-content-between incomplete_wrepper mt-5 pb-4 align-items-center">
                                                            <div className="d-flex">
                                                                <p className="me-3">{index + 1}</p>
                                                                {complete.aiflag === "TRUE" ? <p className="me-3"><img src={complete.qrcode} height="70" width="70" /></p> : ""}
                                                                <h5 className="mb-0">{complete.sheetname}<br /><span>{moment(complete.datetime).format('ll')}</span></h5>

                                                            </div>
                                                            <a onClick={(e) => handleClick(complete.surveyid)} ><img src="images/arrow.svg" className="img-fluid" alt="arrow" /></a>
                                                        </div>
                                                    </>
                                                );
                                            })}

                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Dashboard;